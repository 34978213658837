.transgraph {
    padding: 20px;
  }
  
  .bg-heading-roadmap {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .bg-heading-roadmap h2 {
    font-size: 24px;
    color: white;
  }
  
  .graph-view {
    width: 100%;
    max-width: 100%;
    height: auto;
    position: relative;
    padding-bottom: 56.25%; /* Aspect ratio */
  }
  
  .tradingview-widget-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  section.transgraph .tradingview-widget-container iframe {
    height: calc(100% - 0px) !important;
}
  
  @media (max-width: 768px) {
    .bg-heading-roadmap h2 {
      font-size: 20px;
    }
  
    .graph-view {
      padding-bottom: 75%; /* Increase aspect ratio for taller view */
    }
  }
  
  @media (max-width: 480px) {
    .bg-heading-roadmap h2 {
      font-size: 18px;
    }
  
    .graph-view {
      padding-bottom: 100%; /* Increase aspect ratio for even taller view */
    }
  }
  